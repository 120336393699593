.App {
  text-align: center;
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

/* Añade esto a tu archivo CSS global */
.dark-theme {
  --bg-color: #000; /* o cualquier color oscuro que prefieras */
 /* --bg-color: #152c37;*/
  --text-color: #FDF9FB; /* o cualquier color claro para el texto que prefieras */
}

.light-theme {
  --bg-color: #a0c8e2; /* o cualquier color claro que prefieras */
  --text-color: #FDF9FB; /* o cualquier color oscuro para el texto que prefieras */
}

/* Asegúrate de usar las variables CSS en tu CSS o en las clases de Tailwind */
body {
  background-color: var(--bg-color);
  color: var(--text-color);
  transition: background 0.5s, color 0.5s;
  font-family: 'Inter', sans-serif;
}
.myNav{
  background-color: var(--bg-color);
  color: var(--text-color);
}
.navContainer{
  padding-top: 5rem;
}

.shadow-3d {
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.15), 15px 15px 30px rgba(0, 0, 0, 0.1);
  border: none !important;
}
/* FloatingButton.css */
.floating-btn {
  position: fixed;
  bottom: 50px;
  right: 20px;
  border-radius: 50%;
  padding: 15px;
  z-index: 1000;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.floating-btn:hover {
  background-color: #899aad; /* Color al pasar el cursor */
}

.no-underline {
  text-decoration: none;
}

/* styles.css */
.custom-card {
  border: none !important; /* Esto quita el borde blanco */
}
